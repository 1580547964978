import React, { useMemo, useState,useEffect } from 'react';
import './CollectedBills.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useTable } from 'react-table';
import Slider from 'react-slick';
import { CustomNextArrow, CustomPrevArrow } from './CustomArrow';
import CollectedBillsDropdown from './CollectedBillsDropdown';
import cardData from './cardData';
import { Button } from '../../../components/Button';
import { makeStyles } from '@material-ui/core/styles';
import ImageViewer from './imageCollectedBills';
import getCollectedBills from '../../../actions/creation/getCollectedBills';

const CollectedBills = () => {
  const [selectedCard, setSelectedCard] = useState(0); // Start with the first card selected
  const [isExpanded, setIsExpanded] = useState(true);
  const [editCell, setEditCell] = useState({ rowIndex: null, columnId: null });
  const [editedValue, setEditedValue] = useState('');
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  const handleCardClick = (index) => {
    setSelectedCard(index);
    setIsExpanded(true);
  };

  const toggleExpandCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSaveNext = () => {
    if (selectedCard < cardData.length - 1) {
      setSelectedCard(selectedCard + 1); // Move to the next card
    }
  };

  const handleCellClick = (rowIndex, columnId, value) => {
    setEditCell({ rowIndex, columnId });
    setEditedValue(value);
  };

  const handleBlur = () => {
    if (editCell.rowIndex !== null && selectedCard !== null) {
      cardData[selectedCard].tableData[editCell.rowIndex][editCell.columnId] = editedValue;
      setEditCell({ rowIndex: null, columnId: null });
    }
  };

  useEffect(() => {
    async function fetchData() {
        try {
            setLoading(true);
            const response = await getCollectedBills(); // Pass `req` if needed
            setBills(response.data); // Assuming response contains `data`
            setLoading(false);
        } catch (error) {
            setError(error.message || 'Something went wrong');
            setLoading(false);
        }
    }
    fetchData();
}, []);
  const columns = useMemo(() => {
    if (selectedCard === null) return [];

    const headers = cardData[selectedCard].headers;
    const preferredOrder = [
      'Location4', 'ID', ...headers.filter(header => header !== 'Location4' && header !== 'ID')
    ];

    return preferredOrder.map((header) => ({
      Header: header,
      accessor: header.toLowerCase(),
      Cell: ({ row, value, column }) => {
        const isEditing = editCell.rowIndex === row.index && editCell.columnId === column.id;
        const displayValue = value || "Enter Text";

        if (header === 'Location4') {
          return (
            <CollectedBillsDropdown
              label="Select an Option"
              options={cardData[selectedCard].tableData[row.index].locationArray}
              value={value}
              onChange={(selectedValue) => {
                cardData[selectedCard].tableData[row.index][column.id] = selectedValue;
                setEditedValue(selectedValue);
              }}
            />
          );
        }

        return isEditing ? (
          <input
            type="text"
            value={editedValue}
            onChange={(e) => setEditedValue(e.target.value)}
            onBlur={handleBlur}
            autoFocus
            placeholder="Enter Text"
            style={{
              border: 'none',
              borderBottom: '2px solid #3E3E3E',
              padding: '5px',
              outline: 'none',
              flex: 1,
              marginRight: '10px',
              width: '100%'
            }}
          />
        ) : (
          <div
            className="cell-content"
            onClick={() => handleCellClick(row.index, column.id, value)}
            style={{ color: value ? 'initial' : '#aaa' }}
          >
            {displayValue}
          </div>
        );
      },
    })).concat([
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <div>
            {/* No edit or delete icons */}
          </div>
        ),
      },
    ]);
  }, [selectedCard, editCell, editedValue]);

  const data = useMemo(() => {
    if (selectedCard === null) return [];
    return cardData[selectedCard].tableData;
  }, [selectedCard]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
  };

  return (
    <div>
      <Slider {...sliderSettings}>
  {cardData.map((card, index) => (
    <Button
      key={index}
      onClick={() => handleCardClick(index)}
      changeOnlyBgColor
      border={index === selectedCard || index < selectedCard ? "2px solid green" : "2px solid yellow"} // Dynamic border color
      bgColor={"transparent"}  // Keep the background transparent
      color={index === selectedCard || index < selectedCard ? "green" : "#FFC72C"}  // Dynamic text color
    >
      {card.name}
    </Button>
  ))}
</Slider>

      {selectedCard !== null && (
        <div className="table-container">
          <div>
            <h3>{cardData[selectedCard].name}</h3>
            <div className="table-wrapper">
              <table {...getTableProps()} className="no-border">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <button className="save-next-button" onClick={handleSaveNext}>
              Save and Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CollectedBills;
