import React, { Component, Fragment } from "react";
import { OutletMeta } from "./OutletMeta";
import MobileFriendlyIcon from "@material-ui/icons/MobileFriendly";
import AssignmentIcon from '@material-ui/icons/Assignment';
import VerticalSplitIcon from "@material-ui/icons/VerticalSplit";
import HistoryRoundedIcon from '@material-ui/icons/HistoryRounded';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import {
  DetailsLayoutWrapper,
  LeftSideNavs,
  RightSideContent,
} from "./outlet.styles";
import {
  GET_CALLING_TRACKER,
  GET_OUTLETS_DETAILS,
  UPLOAD_API,
} from "../../../helpers/constants/apiUrl";
import { triggerSimpleAjax } from "../../../helpers/httpHelper";
import Loader from "../../../components/Loader";
import OutletSurvey from "./outlet-survey";
import { OutletLog } from "./outlet-log";
import { QCEditHistory } from "./qc-edit-history";
import { OutletCallingScreen } from "./OutletCallingScreen";
import { connect } from "react-redux";
import { withLastLocation } from "react-router-last-location";
import { IntimationCalling } from "./IntimationCallingScreen";
import PayoutDetailsScreen from "./PayoutDetailsScreen";
import CollectedBills from "./CollectedBills";
import { InvoiceCallingScreen } from "./InvoiceCallingScreen";
import { WORKFLOW_KEYS } from "../../../helpers/constants/workflowKeys";
import { KYCUpdate } from "./KYCUpdate";
import triggerNotification from "../../../actions/triggerNotification";
import { bindActionCreators } from "redux";
import { ConfirmationCalling } from "./ConfirmationCalling";
import { VerifyPhoneNumberBlock } from "./VerifyPhoneNumberBlock";
import { ScoreDetail } from "./ScoreDetail";
import { SUB_PROJECT_TYPES } from "../../../helpers/constants/project-types";
import ScoreDetailV2 from "./ScoreDetailV2";
import LogicScoreWrapper from "./LogicScoreWrapper";
import RetailerEdit from "./RetailerEdit";
import EditIcon from "@material-ui/icons/Edit";
import ImageZoom from "../../../components/ImageZoom";
import ReactTooltip from "react-tooltip";


const duplicateResponse=

{
  "id": 6963725,
  "phone_number1": null,
  "owner_name": "test",
  "created": "2024-07-08T17:13:42.189364",
  "modified": "2024-07-08T17:13:44.785167",
  "name": "add new",
  "slug": "jiziDmpRBbGw9Of1EVQM",
  "status": "active",
  "my_track_mobile_app_id": null,
  "rsid": "RSTCPLAPRZYYGX00013",
  "rsid_to_ignore": null,
  "outlet_id": "RZYYGX",
  "street_name": null,
  "area_name": null,
  "landmark_name": null,
  "address": "test",
  "previous_address": null,
  "pin_code": 123456,
  "distribution_channel": null,
  "distributor_gst_number": null,
  "channel": null,
  "pan_number": null,
  "pan_number_verification_status": "not_verified_yet",
  "gst_number": null,
  "gst_number_verification_status": "not_verified_yet",
  "gst_registration_status": null,
  "latitude": null,
  "longitude": null,
  "previous_phone_number1": null,
  "phone_number2": null,
  "previous_phone_number2": null,
  "is_duplicated": false,
  "is_added_on_the_fly": true,
  "inbound_source": "my_track",
  "workflow_state": "data_ingested",
  "district": null,
  "sub_channel": null,
  "sale_type": null,
  "sub_sale_type": null,
  "regional_manager": null,
  "state_head": null,
  "area_manager": null,
  "sales_executive": null,
  "salesman": null,
  "merchandiser": null,
  "notes": null,
  "qc_display_notes": null,
  "notes_by_field_auditor": "Add new test mytrack",
  "survey_not_done_notes_by_field_auditor": null,
  "other_backend_communicating_errors": null,
  "field_survey_start_latitude": "N/A",
  "field_survey_start_longitude": "N/A",
  "field_survey_start_location_accuracy": null,
  "field_survey_end_latitude": "19.218573",
  "field_survey_end_longitude": "72.8621098",
  "field_survey_end_location_accuracy": null,
  "field_survey_start_datetime": null,
  "field_survey_end_datetime": "2024-07-08T17:13:20",
  "is_kyc_successful": false,
  "temp_query_key": null,
  "temp_filter_query_key": null,
  "other_rsa_form_phone_number": null,
  "created_by": 379092,
  "related_bulk": null,
  "state": {
      "name": "andhra pradesh",
      "id": 3,
      "slug": "andhra-pradesh"
  },
  "city": {
      "name": "10022173 Sri Hyma Agencies",
      "id": 16474,
      "slug": "3019-1-10022173-sri-hyma-agencies",
      "state": 3
  },
  "region": {
      "name": "east",
      "id": 897,
      "slug": "xkKP9PjghNztVUHZPOYq",
      "organisation": 60
  },
  "territory": {
      "zone": null
  },
  "zone": {
      "region": null
  },
  "program": null,
  "panel": null,
  "survey_type": "Ahmedabad",
  "banner_image": {
      "image_caption": null,
      "image": "https://gprs-migration.s3.amazonaws.com/images/108_359_0_banner_image_08_07_2024_17_13_39_960661.jpeg",
      "id": 10681865,
      "height": 2500,
      "width": 1875,
      "size": 231642,
      "content_type": "image/jpeg",
      "image_base64": null,
      "image_content": "",
      "question_id": ""
  },
  "project": {
      "name": "TCPLSALT",
      "id": 108,
      "slug": "tcplsalt-JEu0jjQPr9gconR2Zao7",
      "type": "off_take",
      "organisation": 60,
      "survey_types": [
          "Ahmedabad",
          "Chennai",
          "Kolkata",
          "Ludhiana",
          "Mangalore",
          "Pune",
          "Purnea",
          "Varanasi"
      ],
      "add_data_on_fly": true,
      "is_validation_calling_necessary": true
  },
  "sub_project": {
      "name": "TCPLSALT - empanelment",
      "id": 359,
      "slug": "tcplsalt-empanelment-vuNkWWeuMopMwQmNw5LO",
      "type": "empanelment",
      "project": 108,
      "status": "active",
      "previous_sub_project": null
  },
  "duplication_tracker": null,
  "organisation_tracker": 935672,
  "bank_related_photo_proofs": [],
  "pan_number_photo_proofs": [],
  "gst_number_photo_proofs": [],
  "other_retailer_phone_number_config": [],
  "organisation": {
      "name": "Tata Consumer Products Ltd.",
      "id": 60,
      "slug": "eBZApn7tHOVTlwpQShAi",
      "type": "client_organisation",
      "is_multi_tiered": false,
      "logo": "https://gprs-migration.s3.amazonaws.com/images/Image.jpg"
  },
  "related_workflow_object": {
      "id": 6337815,
      "other_config": {},
      "is_added_on_the_fly": true,
      "workflow_state": "qc_pending",
      "field_audit_start_date": null,
      "field_audit_end_date": null,
      "route_plan_start_date": null,
      "route_plan_end_date": null,
      "qc_inbound_date": "2024-07-08T17:13:43.482961",
      "qc_completed_date": null,
      "type_of_calling": null,
      "calling_inbound_date": null,
      "calling_completed_date": null,
      "spoc_name": null,
      "spoc_phone_number": null,
      "client_payout_month": null,
      "rs_payout_month": null,
      "client_payout_from": null,
      "client_payout_to": null,
      "client_payout_remarks": null,
      "utr_number": null,
      "ifsc_code": null,
      "ifsc_code_verification_status": "not_verified_yet",
      "upi_id": null,
      "upi_account_holder_name": null,
      "account_holder_name": null,
      "account_number": null,
      "bank": null,
      "branch": null,
      "account_type": null,
      "payment_remarks": null,
      "amount_to_pay": null,
      "iftu_plotted_amount_to_pay": null,
      "amount_paid": null,
      "payment_date": null,
      "client_payout_plotted_date": null,
      "payment_status": "not_paid_yet",
      "rsa_verification_code": null,
      "rsa_verification_status": "not_verified_yet",
      "service_name": null,
      "service_description": null,
      "hsn_code": null,
      "invoice_duration_start_date": null,
      "invoice_duration_end_date": null,
      "invoice_number": null,
      "srv_program_name": null,
      "interim_status": null,
      "field_audit_completed_date": "2024-07-08T17:13:42.548124",
      "to_user_level": null,
      "temp_query_key": null,
      "previous_answers_store": null,
      "iftu_tds_percentage": null,
      "iftu_tds_amount": null,
      "iftu_gst_amount": null,
      "iftu_invoice_amount": null,
      "client_given_phone_number": null,
      "field_survey_mobile_version_number": "7",
      "is_answers_synced_from_equivalent_instance": false,
      "legacy_gst_amount_paid": null,
      "legacy_gst_payment_date": null,
      "legacy_gst_utr_number": null,
      "legacy_gst_payment_remarks": null,
      "outlet": 6963725,
      "assigned_field_coordinator": null,
      "assigned_field_manager": null,
      "assigned_field_auditor": {
          "name": "Anurag Prajapati",
          "id": 379092,
          "username": "anuraj-prajapati-EwOkNumdjjEcreHmnCjl",
          "email": null,
          "phone_number": "+919769841555",
          "pan_number": null,
          "last_login": "2024-08-21T14:29:43.839642"
      },
      "assigned_qc_executive": {
          "name": "Jyoti Gupta",
          "id": 194131,
          "username": "jyoti-gupta-QqJxFlXJBsqSPy3Z9r3I",
          "email": "Jyoti.gupta@retail-scan.com",
          "phone_number": null,
          "pan_number": null,
          "last_login": "2023-07-05T18:31:30.080798"
      },
      "assigned_calling_executive": null,
      "assigned_iftu_executive": null,
      "route_plan_tracker": null,
      "payout_tracker": null,
      "ifsc_code_photo_proofs": [],
      "rsa_photo_proofs": [],
      "travel_log": [
          "qc_pending",
          "data_ingested"
      ],
      "calling_display_notes": null,
      "calling_inbound_source_differentiation": null,
      "reason_log": [],
      "overall_remarks": null,
      "assigned_calling_manager": null,
      "assigned_qc_manager": {
          "name": "Saili Pardhi",
          "id": 178715,
          "username": "saili-pardhi-E9o8NRtEUqU7kvt8qtrB",
          "email": "Sailipardhi@retail-scan.com",
          "phone_number": null,
          "pan_number": null,
          "last_login": "2023-11-02T17:28:13.754841"
      },
      "assigned_iftu_manager": null
  },
  "previous_outlet": null,
  "logs": [
      {
          "message": "The system auto transited and moved this instance from field_added to qc_pending.",
          "workflow_object": 6337815,
          "created": "2024-07-08T17:13:43.439031"
      },
      {
          "message": "The system auto transited and moved this instance from not_assigned_yet to data_ingested.",
          "workflow_object": 6337815,
          "created": "2024-07-08T17:13:42.189364"
      }
  ],
  "answer_action_logs": [],
  "calling_remarks": null,
  "question_and_answers": {
      "2629": [
          {
              "name": "Store Name",
              "id": 66470,
              "slug": "FbBoLhTl4AtPy79Uy8cS",
              "type": "text",
              "validations": {
                  "min_length": "1",
                  "max_length": "50"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148351,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66470,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "dhhe",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "text",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Store Type",
              "id": 66471,
              "slug": "Z82pEMvVCvLxtimAobxQ",
              "type": "single_choice",
              "validations": {
                  "choices": "salt wholesaler;semi wholesaler;self service"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148356,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66471,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "semi wholesaler",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Store Size Operating area",
              "id": 66472,
              "slug": "uKhdmLEFf9jafJsC2NQY",
              "type": "number",
              "validations": {
                  "allow_decimals": "false",
                  "upper_limit": "999999",
                  "lower_limit": "1"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148352,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66472,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "62",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Store Size Godown area",
              "id": 66668,
              "slug": "7Q1bAQqvqdJ1l1wXUI5M",
              "type": "number",
              "validations": {
                  "allow_decimals": "false",
                  "upper_limit": "999999",
                  "lower_limit": "1"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148355,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66668,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "6",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "No. of Employees (Incl. Store owner)",
              "id": 66667,
              "slug": "U3sc1sJ34iirWU6AyKuu",
              "type": "number",
              "validations": {
                  "allow_decimals": "false",
                  "lower_limit": "1",
                  "upper_limit": "10"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148357,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66667,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "6",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Store Opening Time",
              "id": 66673,
              "slug": "qUzLxMpResSuJT1FTirs",
              "type": "time",
              "validations": {
                  "greater_than": null,
                  "lower_than": null
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148354,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66673,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "17:10:00",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "time",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Store Closing Time",
              "id": 66674,
              "slug": "pbZ1F3e43LlRhv9lW7MG",
              "type": "time",
              "validations": {
                  "greater_than": null,
                  "lower_than": null
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148360,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66674,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "17:10:00",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "time",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Mid day Closure (Railway Time)",
              "id": 66675,
              "slug": "gvCynilQtouF1ZoPzvMN",
              "type": "location",
              "validations": {},
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148363,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66675,
                      "longitude_input": "72.8621098",
                      "latitude_input": "19.218573",
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "location",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Weekly off (Day)",
              "id": 66676,
              "slug": "090EUkUVdPKXG22J2JY1",
              "type": "text",
              "validations": {
                  "min_length": "1",
                  "max_length": "100"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148358,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66676,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "hh",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "text",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "POS Availaibility",
              "id": 66677,
              "slug": "71ZjhMxMCxij4BBUn9CO",
              "type": "yes_or_no",
              "validations": {
                  "choices": "yes;no"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148353,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66677,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "yes",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "yes_or_no",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Cooling Equipment (Electrical)",
              "id": 66678,
              "slug": "b4QL4JvkRzP5x07APKpU",
              "type": "yes_or_no",
              "validations": {
                  "choices": "yes;no"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148364,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66678,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "no",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "yes_or_no",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "No. of Cooling Equipment (Electrical)",
              "id": 66679,
              "slug": "QrybArVBlYAvG0zCVsHb",
              "type": "number",
              "validations": {
                  "allow_decimals": "false",
                  "upper_limit": "999999",
                  "lower_limit": "1"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148393,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66679,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Monthly Salt Turnover Claimed",
              "id": 66669,
              "slug": "jR1jMWy761xsSoSbLVNX",
              "type": "single_choice",
              "validations": {
                  "choices": "rs 1000 - rs 2000;rs 2001 - rs 5000;rs 5001 - rs 7500;rs 7501 - rs 10000;above rs 10000"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148365,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66669,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "rs 2001 - rs 5000",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Per day Turn Over Slab Auditor",
              "id": 66670,
              "slug": "8L7WRn89LUAcoRlo1Dpw",
              "type": "single_choice",
              "validations": {
                  "choices": "rs 1000 - rs 2000;rs 2001 - rs 5000;rs 5001 - rs 7500;rs 7501 - rs 10000;above rs 10000"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148361,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66670,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "rs 2001 - rs 5000",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Name of Store SPOC",
              "id": 66671,
              "slug": "y0WjjVidRY6pvFUeuZth",
              "type": "text",
              "validations": {
                  "min_length": "1",
                  "max_length": "50"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148359,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66671,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "bb",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "text",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Contact No. of Store SPOC",
              "id": 66672,
              "slug": "ZOloPzivrQrzb5QPga0k",
              "type": "phone_number",
              "validations": {},
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148362,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66672,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "1234567790",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "phone_number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Single choice",
              "id": 118248,
              "slug": "zQCRQlOYUhA71qaXptJ7",
              "type": "single_choice",
              "validations": {
                  "choices": "Paving;Purrent"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148394,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118248,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Single choice check Caps",
              "id": 118249,
              "slug": "3BVYNYSJvhL0FeNDWjYU",
              "type": "single_choice",
              "validations": {},
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148395,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118249,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Single choice check small",
              "id": 118250,
              "slug": "qe7CKuSROa0aqg3q9dL4",
              "type": "single_choice",
              "validations": {},
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148396,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118250,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Single choice check small 2",
              "id": 118252,
              "slug": "IQcwzjgBI7Kljx3SrL7x",
              "type": "single_choice",
              "validations": {
                  "choices": "present;absent"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148397,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118252,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Single choice check Caps 2",
              "id": 118253,
              "slug": "XYYvT1SvIJsvhFlDUuvf",
              "type": "single_choice",
              "validations": {
                  "choices": "present;absent"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148398,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118253,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Single choice check Caps 3",
              "id": 118254,
              "slug": "nwkACtDSBoZugcwFgzAg",
              "type": "single_choice",
              "validations": {
                  "choices": "present;absent"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148399,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118254,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Single choice check small 4",
              "id": 118255,
              "slug": "ykRW5T4lceZiQzBAOTJC",
              "type": "single_choice",
              "validations": {
                  "choices": "in;out"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148400,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118255,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Single choice Caps 4",
              "id": 118257,
              "slug": "NlRY3YChgvqb0usu4H2x",
              "type": "single_choice",
              "validations": {
                  "choices": "in;out"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148401,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118257,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Single choice check small 5",
              "id": 118258,
              "slug": "mvkyTJl7mpNUAzO2Zimy",
              "type": "single_choice",
              "validations": {
                  "choices": "in;out"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148402,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118258,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Single choice check Edit",
              "id": 118259,
              "slug": "hihOK8g6s2TNPOWQtYec",
              "type": "single_choice",
              "validations": {
                  "choices": "going;coming"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148403,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118259,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Single choice check Edit 2",
              "id": 118261,
              "slug": "mo70C6N3GkbAAxKG5CoD",
              "type": "single_choice",
              "validations": {
                  "choices": "Mouse;Keypad"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148404,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118261,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Single choice check Edit 3",
              "id": 118262,
              "slug": "2vDgKP7J3bJN0QQrvXJG",
              "type": "single_choice",
              "validations": {
                  "choices": "screen;monitor"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148405,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118262,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "check",
              "id": 118263,
              "slug": "5ozDhGzJEiGoODxqsOyK",
              "type": "single_choice",
              "validations": {
                  "choices": "wire;mobile"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148406,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118263,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "check 1",
              "id": 118264,
              "slug": "BxRiffjADXXE1Bs1GRBb",
              "type": "single_choice",
              "validations": {
                  "choices": "wire;mobile"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148407,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118264,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "check 3",
              "id": 118265,
              "slug": "4Fdxs0CJQzs7VzBesgOZ",
              "type": "single_choice",
              "validations": {
                  "choices": "wire;mobile"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148408,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118265,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "checking 1",
              "id": 118266,
              "slug": "q21Yi0WI9mnZ9NOva3gL",
              "type": "single_choice",
              "validations": {
                  "choices": "bag;charger"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148409,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118266,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "checking 2",
              "id": 118267,
              "slug": "Xlxk8lxUgmCJYy83kmh4",
              "type": "single_choice",
              "validations": {
                  "choices": "bag;charger"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148410,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118267,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "checking 3",
              "id": 118268,
              "slug": "iQSzOb4DobeNx9EEpJjS",
              "type": "single_choice",
              "validations": {
                  "choices": "bag;charger"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148411,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118268,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "check",
              "id": 118269,
              "slug": "7OB0IxUT6Iekxi9ftr8X",
              "type": "single_choice",
              "validations": {
                  "choices": "Mug;Cable"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148412,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118269,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "check",
              "id": 118270,
              "slug": "Hrjq8BIZHpX3SYejepDj",
              "type": "single_choice",
              "validations": {
                  "choices": "Mug;Cable"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148413,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118270,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "check 3",
              "id": 118271,
              "slug": "VLkN3eFEMfL3tJ527wGx",
              "type": "single_choice",
              "validations": {
                  "choices": "Mug;Cable"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148414,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118271,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "edit",
              "id": 118272,
              "slug": "VOW7zfoHDFbUBvcl62Dl",
              "type": "single_choice",
              "validations": {
                  "choices": "tulip;jasmine"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148415,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118272,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "edit",
              "id": 118273,
              "slug": "rCMRvvPSmdedOlAE9G0Q",
              "type": "single_choice",
              "validations": {
                  "choices": "small;capital"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148416,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118273,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "edit",
              "id": 118274,
              "slug": "27hDbMWEqWF5TSIhNpAq",
              "type": "single_choice",
              "validations": {
                  "choices": "Edit;Editing"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148417,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118274,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "test",
              "id": 118275,
              "slug": "rY5iXv4p6XInSH6LJtEH",
              "type": "single_choice",
              "validations": {
                  "choices": "test;testing"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148418,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118275,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "test 2",
              "id": 118276,
              "slug": "9vjOY82NQBi6WLZDMjOn",
              "type": "single_choice",
              "validations": {
                  "choices": "test;testing"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148419,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118276,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "test 3",
              "id": 118277,
              "slug": "wcdOK7pwhTaoYT7QOCzS",
              "type": "single_choice",
              "validations": {
                  "choices": "test;testing"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148420,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 118277,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Multiple",
              "id": 120452,
              "slug": "BjVRINxlVX5AMkEATLQV",
              "type": "multiple_choice",
              "validations": {
                  "choices": "abc;def;xyz;123;456"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148421,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120452,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          }
      ],
      "2633": [
          {
              "name": "Bank Name",
              "id": 66680,
              "slug": "NWI7aRuix62qEFSPcJZg",
              "type": "text",
              "validations": {
                  "min_length": "1",
                  "max_length": "100"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148422,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66680,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "text",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Branch Name (As per Cheque)",
              "id": 66681,
              "slug": "gDn7c91530b53bJ99p9M",
              "type": "text",
              "validations": {
                  "min_length": "1",
                  "max_length": "100"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148423,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66681,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "text",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Bank Account Number",
              "id": 66682,
              "slug": "rpKTU2y4hwJIvLER40WS",
              "type": "account_number",
              "validations": {},
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148424,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66682,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "account_number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "IFSC Code",
              "id": 66683,
              "slug": "4qmrc834t5kAAJu9gkhC",
              "type": "ifsc_code",
              "validations": {},
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148425,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66683,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "ifsc_code",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Bank Account Type",
              "id": 66684,
              "slug": "Xjrl6iNz8tEvXMQOET1X",
              "type": "single_choice",
              "validations": {
                  "choices": "savings account;current account"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148426,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66684,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Account Holder Name",
              "id": 66685,
              "slug": "AcMftHuel4YetQaq6Lma",
              "type": "text",
              "validations": {
                  "min_length": "1",
                  "max_length": "100"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148427,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66685,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "text",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Cheque / Passbook Image",
              "id": 66686,
              "slug": "N3wqXFI9CAuUFns473og",
              "type": "multiple_image",
              "validations": {
                  "min_images_count": "1",
                  "max_images_count": "2"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148428,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66686,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_image",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          }
      ],
      "2634": [
          {
              "name": "UPI ID",
              "id": 66687,
              "slug": "JMUuMBKNuG9ENNWqKh0c",
              "type": "upi_id",
              "validations": {},
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148429,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66687,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "upi_id",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Beneficiary Name",
              "id": 66688,
              "slug": "DzuUfcgu51245NlaiIpT",
              "type": "text",
              "validations": {
                  "min_length": "1",
                  "max_length": "100"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148430,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66688,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "text",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "UPI Contact Number",
              "id": 66689,
              "slug": "W8OR3b0Ew00rSD9ilNwa",
              "type": "phone_number",
              "validations": {},
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148431,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66689,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "phone_number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "UPI Scanner Image",
              "id": 66690,
              "slug": "CyLamzbTz9QHIsrKYpvu",
              "type": "single_image",
              "validations": {},
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148432,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 66690,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "single_image",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          }
      ],
      "2635": [
          {
              "name": "Ankur Salt 1 Kg",
              "id": 66691,
              "slug": "sB9HqCRG3IdGG3EEdXUT",
              "type": "number",
              "validations": {},
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148366,
                      "sku_product": 13157,
                      "workflow_object": 6337815,
                      "question": 66691,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [
                          {
                              "id": 10681866,
                              "image": "https://gprs-migration.s3.amazonaws.com/images/108_359__66691_08_07_2024_17_13_40_375534.jpeg"
                          }
                      ],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148456,
                      "sku_product": 6868,
                      "workflow_object": 6337815,
                      "question": 66691,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148457,
                      "sku_product": 6869,
                      "workflow_object": 6337815,
                      "question": 66691,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148458,
                      "sku_product": 6870,
                      "workflow_object": 6337815,
                      "question": 66691,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148459,
                      "sku_product": 6871,
                      "workflow_object": 6337815,
                      "question": 66691,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Nirma Shudh Salt 1 Kg",
              "id": 66695,
              "slug": "9QZIKUqNN2KcSsoDXwLx",
              "type": "number",
              "validations": {},
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148472,
                      "sku_product": 6868,
                      "workflow_object": 6337815,
                      "question": 66695,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148473,
                      "sku_product": 6869,
                      "workflow_object": 6337815,
                      "question": 66695,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148474,
                      "sku_product": 6870,
                      "workflow_object": 6337815,
                      "question": 66695,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148475,
                      "sku_product": 6871,
                      "workflow_object": 6337815,
                      "question": 66695,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Sahyog Salt 1 Kg",
              "id": 66698,
              "slug": "j2fQaP8GwBbzNJ4ZDVK0",
              "type": "number",
              "validations": {},
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148484,
                      "sku_product": 6868,
                      "workflow_object": 6337815,
                      "question": 66698,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148485,
                      "sku_product": 6869,
                      "workflow_object": 6337815,
                      "question": 66698,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148486,
                      "sku_product": 6870,
                      "workflow_object": 6337815,
                      "question": 66698,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148487,
                      "sku_product": 6871,
                      "workflow_object": 6337815,
                      "question": 66698,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          }
      ],
      "2637": [
          {
              "name": "Text Count  Dummy",
              "id": 83746,
              "slug": "s4pBlvMQPqRaZVKHSlh5",
              "type": "number",
              "validations": {
                  "allow_decimals": "false",
                  "upper_limit": "999999",
                  "lower_limit": "1"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148436,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 83746,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          }
      ],
      "3703": [
          {
              "name": "single choice",
              "id": 120453,
              "slug": "zeg6cqIve7jBRyahqqUm",
              "type": "number",
              "validations": {},
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148564,
                      "sku_product": 12940,
                      "workflow_object": 6337815,
                      "question": 120453,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148565,
                      "sku_product": 12941,
                      "workflow_object": 6337815,
                      "question": 120453,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148566,
                      "sku_product": 12942,
                      "workflow_object": 6337815,
                      "question": 120453,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "single 1",
              "id": 120454,
              "slug": "SNd4XPZgFltKsfD7qwwJ",
              "type": "number",
              "validations": {},
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148567,
                      "sku_product": 12940,
                      "workflow_object": 6337815,
                      "question": 120454,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148568,
                      "sku_product": 12941,
                      "workflow_object": 6337815,
                      "question": 120454,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148569,
                      "sku_product": 12942,
                      "workflow_object": 6337815,
                      "question": 120454,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "single 2",
              "id": 120455,
              "slug": "JBOp70BoIT1nxrcJXS8p",
              "type": "number",
              "validations": {},
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148570,
                      "sku_product": 12940,
                      "workflow_object": 6337815,
                      "question": 120455,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148571,
                      "sku_product": 12941,
                      "workflow_object": 6337815,
                      "question": 120455,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148572,
                      "sku_product": 12942,
                      "workflow_object": 6337815,
                      "question": 120455,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          }
      ],
      "3704": [
          {
              "name": "single choice",
              "id": 120453,
              "slug": "zeg6cqIve7jBRyahqqUm",
              "type": "number",
              "validations": {},
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148564,
                      "sku_product": 12940,
                      "workflow_object": 6337815,
                      "question": 120453,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148565,
                      "sku_product": 12941,
                      "workflow_object": 6337815,
                      "question": 120453,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148566,
                      "sku_product": 12942,
                      "workflow_object": 6337815,
                      "question": 120453,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "single 1",
              "id": 120454,
              "slug": "SNd4XPZgFltKsfD7qwwJ",
              "type": "number",
              "validations": {},
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148567,
                      "sku_product": 12940,
                      "workflow_object": 6337815,
                      "question": 120454,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148568,
                      "sku_product": 12941,
                      "workflow_object": 6337815,
                      "question": 120454,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148569,
                      "sku_product": 12942,
                      "workflow_object": 6337815,
                      "question": 120454,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "single 2",
              "id": 120455,
              "slug": "JBOp70BoIT1nxrcJXS8p",
              "type": "number",
              "validations": {},
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148570,
                      "sku_product": 12940,
                      "workflow_object": 6337815,
                      "question": 120455,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148571,
                      "sku_product": 12941,
                      "workflow_object": 6337815,
                      "question": 120455,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  },
                  {
                      "id": 221148572,
                      "sku_product": 12942,
                      "workflow_object": 6337815,
                      "question": 120455,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          }
      ],
      "3719": [
          {
              "name": "Question 1",
              "id": 120942,
              "slug": "5ENnaWNZffo65khk0Ko8",
              "type": "number",
              "validations": {
                  "lower_limit": "0",
                  "allow_decimals": "true",
                  "upper_limit": "99"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148368,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120942,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "2",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Question 2",
              "id": 120943,
              "slug": "EnWiMyGANKaitb7obPW5",
              "type": "multiple_choice",
              "validations": {
                  "choices": "window display;fsu;ctu;wall /pillar/beam branding;dealer board;poster/wobbler/stickers/hangers"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148377,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120943,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": [
                          "dealer board"
                      ]
                  }
              ]
          },
          {
              "name": "Question 3",
              "id": 120944,
              "slug": "SRytZ5QVQQosDnNSfu4U",
              "type": "multiple_image",
              "validations": {
                  "min_images_count": "1",
                  "max_images_count": "20"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148373,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120944,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_image",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [
                          {
                              "id": 10681869,
                              "image": "https://gprs-migration.s3.amazonaws.com/images/108_359__120944_08_07_2024_17_13_40_864534.jpeg"
                          }
                      ],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Question 4",
              "id": 120945,
              "slug": "BbLql8VqZogO4QaSkQFp",
              "type": "number",
              "validations": {
                  "lower_limit": "0",
                  "allow_decimals": "true",
                  "upper_limit": "99"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148367,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120945,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "2",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Question 5",
              "id": 120946,
              "slug": "5TkEKfCTxGHXqivWUPx6",
              "type": "multiple_choice",
              "validations": {
                  "choices": "window display;fsu;ctu;wall /pillar/beam branding;dealer board;poster/wobbler/stickers/hangers"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148376,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120946,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": [
                          "wall /pillar/beam branding"
                      ]
                  }
              ]
          },
          {
              "name": "Question 6",
              "id": 120947,
              "slug": "ipslC829eAs0xnW73XyI",
              "type": "multiple_image",
              "validations": {
                  "min_images_count": "1",
                  "max_images_count": "20"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148371,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120947,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_image",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [
                          {
                              "id": 10681867,
                              "image": "https://gprs-migration.s3.amazonaws.com/images/108_359__120947_08_07_2024_17_13_40_500799.jpeg"
                          }
                      ],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Question 7",
              "id": 120948,
              "slug": "X1PnZJxLilmN4OyRGxCn",
              "type": "number",
              "validations": {
                  "lower_limit": "0",
                  "allow_decimals": "true",
                  "upper_limit": "99"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148380,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120948,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "55",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Question 8",
              "id": 120949,
              "slug": "HhgADZywuQTpGg5ueFuD",
              "type": "multiple_choice",
              "validations": {
                  "choices": "window display;fsu;ctu;wall /pillar/beam branding;dealer board;poster/wobbler/stickers/hangers"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148370,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120949,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": [
                          "dealer board"
                      ]
                  }
              ]
          },
          {
              "name": "Question 9",
              "id": 120950,
              "slug": "5Z1ZPNKFnisqZvizbMF0",
              "type": "multiple_image",
              "validations": {
                  "min_images_count": "1",
                  "max_images_count": "20"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148372,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120950,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_image",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [
                          {
                              "id": 10681868,
                              "image": "https://gprs-migration.s3.amazonaws.com/images/108_359__120950_08_07_2024_17_13_40_671293.jpeg"
                          }
                      ],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Question 10",
              "id": 120951,
              "slug": "psehzpTTgHbgY8IZW4Ze",
              "type": "number",
              "validations": {
                  "lower_limit": "0",
                  "allow_decimals": "true",
                  "upper_limit": "99"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148378,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120951,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "66",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Question 11",
              "id": 120952,
              "slug": "SVGXsPp7m1FPIJOXE9Lp",
              "type": "multiple_choice",
              "validations": {
                  "choices": "window display;fsu;ctu;wall /pillar/beam branding;dealer board;poster/wobbler/stickers/hangers"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148369,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120952,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": [
                          "dealer board",
                          "wall /pillar/beam branding"
                      ]
                  }
              ]
          },
          {
              "name": "Question 12",
              "id": 120953,
              "slug": "5AbBGRyuVMOgZMgVfpy1",
              "type": "multiple_image",
              "validations": {
                  "min_images_count": "1",
                  "max_images_count": "20"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148379,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120953,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_image",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [
                          {
                              "id": 10681870,
                              "image": "https://gprs-migration.s3.amazonaws.com/images/108_359__120953_08_07_2024_17_13_40_975551.jpeg"
                          }
                      ],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "No of Shelf of Pampers",
              "id": 120954,
              "slug": "u7HPlNnb2z7NV2uBCOgO",
              "type": "number",
              "validations": {
                  "lower_limit": "0",
                  "allow_decimals": "true",
                  "upper_limit": "99"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148374,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120954,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "9",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Branded Pampers Assets",
              "id": 120955,
              "slug": "NXADMiNOaMxfzvd1nITx",
              "type": "multiple_choice",
              "validations": {
                  "choices": "window display;fsu;ctu;wall /pillar/beam branding;dealer board;poster/wobbler/stickers/hangers"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148437,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120955,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Images of Pampers Assets",
              "id": 120956,
              "slug": "XNy6SAw77yyvieH6b4TX",
              "type": "multiple_image",
              "validations": {
                  "min_images_count": "1",
                  "max_images_count": "20"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148438,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120956,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_image",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "No of Shelf of MamyPoko",
              "id": 120957,
              "slug": "3JNJMyoR3mi3fshERm24",
              "type": "number",
              "validations": {
                  "lower_limit": "0",
                  "allow_decimals": "true",
                  "upper_limit": "99"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148439,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120957,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Branded MamyPoko Assets",
              "id": 120958,
              "slug": "zv7uoZZLt9RR0Eyk26CR",
              "type": "multiple_choice",
              "validations": {
                  "choices": "window display;fsu;ctu;wall /pillar/beam branding;dealer board;poster/wobbler/stickers/hangers"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148440,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120958,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Images of MamyPoko Assets",
              "id": 120959,
              "slug": "X6v9sjeHEGGEGZAsapZb",
              "type": "multiple_image",
              "validations": {
                  "min_images_count": "1",
                  "max_images_count": "20"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148441,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120959,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_image",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "No of Shelf of Huggies",
              "id": 120960,
              "slug": "jGqaAkOodzVIEbR8tra1",
              "type": "number",
              "validations": {
                  "lower_limit": "0",
                  "allow_decimals": "true",
                  "upper_limit": "99"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148442,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120960,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Branded Huggies Assets",
              "id": 120961,
              "slug": "S905l6ublVZ5A4yc8zBj",
              "type": "multiple_choice",
              "validations": {
                  "choices": "window display;fsu;ctu;wall /pillar/beam branding;dealer board;poster/wobbler/stickers/hangers"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148443,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120961,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Images of Huggies Assets",
              "id": 120962,
              "slug": "c8oF49sI5EgExE5NVln6",
              "type": "multiple_image",
              "validations": {
                  "min_images_count": "1",
                  "max_images_count": "20"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148444,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120962,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_image",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "New Sub Question",
              "id": 120963,
              "slug": "Tv4XDDnh53rkY54rxw5g",
              "type": "text",
              "validations": {
                  "min_length": "1",
                  "max_length": "100"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148375,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120963,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "wh",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "text",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          }
      ],
      "3720": [
          {
              "name": "Test 1",
              "id": 120964,
              "slug": "Ls22jnDItZ8rQtc2UCNa",
              "type": "multiple_choice",
              "validations": {
                  "choices": "abc;def;xyz"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148445,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120964,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Test 2",
              "id": 120965,
              "slug": "kWttXRt1Yu9Vhf4WuC4M",
              "type": "multiple_image",
              "validations": {
                  "min_images_count": "1",
                  "max_images_count": "100"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148446,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 120965,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_image",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          }
      ],
      "3755": [
          {
              "name": "Question 1",
              "id": 121583,
              "slug": "Z0hzHHrYap8QpRPDWWYF",
              "type": "number",
              "validations": {
                  "lower_limit": "0",
                  "allow_decimals": "true",
                  "upper_limit": "99"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148381,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121583,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "2",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Question 2",
              "id": 121584,
              "slug": "6WPTi8rKSJkmzedzpIyD",
              "type": "dropdown",
              "validations": {
                  "choices": "window display;fsu;ctu;wall /pillar/beam branding;dealer board;poster/wobbler/stickers/hangers"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148382,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121584,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "wall /pillar/beam branding",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "dropdown",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Question 3",
              "id": 121585,
              "slug": "BfmWwtXzjYJfL4R3a6FZ",
              "type": "multiple_image",
              "validations": {
                  "min_images_count": "1",
                  "max_images_count": "20"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148385,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121585,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_image",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [
                          {
                              "id": 10681871,
                              "image": "https://gprs-migration.s3.amazonaws.com/images/108_359__121585_08_07_2024_17_13_41_124016.jpeg"
                          }
                      ],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Question 4",
              "id": 121586,
              "slug": "BDn5SiYsPZhkoNmBO316",
              "type": "number",
              "validations": {
                  "lower_limit": "0",
                  "allow_decimals": "true",
                  "upper_limit": "99"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148389,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121586,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "6",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Question 5",
              "id": 121587,
              "slug": "9qXNg1e4614VhKvDV8cr",
              "type": "dropdown",
              "validations": {
                  "choices": "window display;fsu;ctu;wall /pillar/beam branding;dealer board;poster/wobbler/stickers/hangers"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148384,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121587,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "fsu",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "dropdown",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Question 6",
              "id": 121588,
              "slug": "q923pHDkrX5jwPuRkmRm",
              "type": "multiple_image",
              "validations": {
                  "min_images_count": "1",
                  "max_images_count": "20"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148392,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121588,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_image",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [
                          {
                              "id": 10681874,
                              "image": "https://gprs-migration.s3.amazonaws.com/images/108_359__121588_08_07_2024_17_13_41_590197.jpeg"
                          }
                      ],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Question 7",
              "id": 121589,
              "slug": "XgxeJJzqzCig4LAA9iif",
              "type": "number",
              "validations": {
                  "lower_limit": "0",
                  "allow_decimals": "true",
                  "upper_limit": "99"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148386,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121589,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "55",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Question 8",
              "id": 121590,
              "slug": "6Kb8cBavXDKcKGaTsUwg",
              "type": "dropdown",
              "validations": {
                  "choices": "window display;fsu;ctu;wall /pillar/beam branding;dealer board;poster/wobbler/stickers/hangers"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148383,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121590,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "wall /pillar/beam branding",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "dropdown",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Question 9",
              "id": 121591,
              "slug": "YnuwGfwnCP7y6dvYCHsa",
              "type": "multiple_image",
              "validations": {
                  "min_images_count": "1",
                  "max_images_count": "20"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148387,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121591,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_image",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [
                          {
                              "id": 10681872,
                              "image": "https://gprs-migration.s3.amazonaws.com/images/108_359__121591_08_07_2024_17_13_41_265839.jpeg"
                          }
                      ],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Question 10",
              "id": 121592,
              "slug": "rOKcCPHeHG1DXYUAzjxt",
              "type": "number",
              "validations": {
                  "lower_limit": "0",
                  "allow_decimals": "true",
                  "upper_limit": "99"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148391,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121592,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "9",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Question 11",
              "id": 121593,
              "slug": "UHBDXbt6XgKFLCS5MNTo",
              "type": "dropdown",
              "validations": {
                  "choices": "window display;fsu;ctu;wall /pillar/beam branding;dealer board;poster/wobbler/stickers/hangers"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148388,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121593,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": "ctu",
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "dropdown",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Question 12",
              "id": 121594,
              "slug": "fBOnWacqjCg8RajDOuSN",
              "type": "multiple_image",
              "validations": {
                  "min_images_count": "1",
                  "max_images_count": "20"
              },
              "is_calling_necessary": true,
              "related_answers": [
                  {
                      "id": 221148390,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121594,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": false,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_image",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [
                          {
                              "id": 10681873,
                              "image": "https://gprs-migration.s3.amazonaws.com/images/108_359__121594_08_07_2024_17_13_41_443585.jpeg"
                          }
                      ],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "No of Shelf of Pampers",
              "id": 121595,
              "slug": "dTQPYOqptXtj5P1OG2Xp",
              "type": "number",
              "validations": {
                  "lower_limit": "0",
                  "allow_decimals": "true",
                  "upper_limit": "99"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148447,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121595,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Branded Pampers Assets",
              "id": 121596,
              "slug": "laJcW0eTD8TjzZiuNBwo",
              "type": "multiple_choice",
              "validations": {
                  "choices": "window display;fsu;ctu;wall /pillar/beam branding;dealer board;poster/wobbler/stickers/hangers"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148448,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121596,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Images of Pampers Assets",
              "id": 121597,
              "slug": "yGYshhXJnT62YjMkTcx9",
              "type": "multiple_image",
              "validations": {
                  "min_images_count": "1",
                  "max_images_count": "20"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148449,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121597,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_image",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "No of Shelf of MamyPoko",
              "id": 121598,
              "slug": "CBzH29PmnJEF6FfyaBoI",
              "type": "number",
              "validations": {
                  "lower_limit": "0",
                  "allow_decimals": "true",
                  "upper_limit": "99"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148450,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121598,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Branded MamyPoko Assets",
              "id": 121599,
              "slug": "ClyXXBXoR8RVAK4Sa5UL",
              "type": "multiple_choice",
              "validations": {
                  "choices": "window display;fsu;ctu;wall /pillar/beam branding;dealer board;poster/wobbler/stickers/hangers"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148451,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121599,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Images of MamyPoko Assets",
              "id": 121600,
              "slug": "yxLr9B9hE49IqCovOyKu",
              "type": "multiple_image",
              "validations": {
                  "min_images_count": "1",
                  "max_images_count": "20"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148452,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121600,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_image",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "No of Shelf of Huggies",
              "id": 121601,
              "slug": "qZwRrusMpeAhsdHJlA4t",
              "type": "number",
              "validations": {
                  "lower_limit": "0",
                  "allow_decimals": "true",
                  "upper_limit": "99"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148453,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121601,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "number",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Branded Huggies Assets",
              "id": 121602,
              "slug": "GdDFiapvf2m55AAGcCnY",
              "type": "multiple_choice",
              "validations": {
                  "choices": "window display;fsu;ctu;wall /pillar/beam branding;dealer board;poster/wobbler/stickers/hangers"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148454,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121602,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_choice",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          },
          {
              "name": "Images of Huggies Assets",
              "id": 121603,
              "slug": "2pPlmAuHLARNP9fIRSjt",
              "type": "multiple_image",
              "validations": {
                  "min_images_count": "1",
                  "max_images_count": "20"
              },
              "is_calling_necessary": false,
              "related_answers": [
                  {
                      "id": 221148455,
                      "sku_product": null,
                      "workflow_object": 6337815,
                      "question": 121603,
                      "longitude_input": null,
                      "latitude_input": null,
                      "single_input": null,
                      "has_called_and_verified": false,
                      "remarks": null,
                      "is_created_because_unanswered": true,
                      "other_bank_name": null,
                      "other_branch_name": null,
                      "other_ifsc_code": null,
                      "other_phone_number": null,
                      "other_pan_holder_name": null,
                      "other_gst_trade_name": null,
                      "other_gst_legal_name": null,
                      "other_gst_registration_status": null,
                      "other_account_holder_name": null,
                      "verified": false,
                      "verified_secondary": false,
                      "other_upi_account_name": null,
                      "answer_type": "multiple_image",
                      "previous_answers": {},
                      "single_image_input": null,
                      "multiple_images_input": [],
                      "multiple_answers_input": []
                  }
              ]
          }
      ]
  },
  "qc_remarks": null,
  "client_remarks": null,
  "question_groups": [
      {
          "slug": "0SEJddg0vPyE7XIrKnRz",
          "name": "Store Details",
          "id": 2629,
          "type": "regular_group",
          "related_sku_products": [],
          "index": 1
      },
      {
          "slug": "Zq3dZCUN5Dz1QYilZ9FY",
          "name": "Bank Details",
          "id": 2633,
          "type": "regular_group",
          "related_sku_products": [],
          "index": 2
      },
      {
          "slug": "J898tF3hGwbmX0cijq86",
          "name": "UPI Details",
          "id": 2634,
          "type": "regular_group",
          "related_sku_products": [],
          "index": 3
      },
      {
          "slug": "c8w7YgSCFfyxlo55Unk4",
          "name": "Powder Salt",
          "id": 2635,
          "type": "sku_group",
          "related_sku_products": [
              {
                  "name": "sku availability",
                  "id": 6868,
                  "slug": "Pjkfu3prLcxQHIUUL0lg",
                  "question_group": 2635,
                  "type": "yes_or_no",
                  "validations": {
                      "choices": "yes;no"
                  },
                  "is_mandatory": false
              },
              {
                  "name": "sku image",
                  "id": 6869,
                  "slug": "Y7uUuHBLnL223DgSEipi",
                  "question_group": 2635,
                  "type": "multiple_image",
                  "validations": {
                      "min_images_count": "1",
                      "max_images_count": "3"
                  },
                  "is_mandatory": false
              },
              {
                  "name": "monthly fop",
                  "id": 6870,
                  "slug": "wlT40KrAJAvBmlfTcTLG",
                  "question_group": 2635,
                  "type": "number",
                  "validations": {
                      "allow_decimals": "false",
                      "lower_limit": "1",
                      "upper_limit": "10"
                  },
                  "is_mandatory": false
              },
              {
                  "name": "no. of bags purchase in month",
                  "id": 6871,
                  "slug": "3GMT9XXyJDQfy4EISDRW",
                  "question_group": 2635,
                  "type": "number",
                  "validations": {
                      "allow_decimals": "false",
                      "upper_limit": "999999",
                      "lower_limit": "1"
                  },
                  "is_mandatory": false
              },
              {
                  "name": "date check 2",
                  "id": 12776,
                  "slug": "QrCtX31SCiE3B5jmczob",
                  "question_group": 2635,
                  "type": "date",
                  "validations": {},
                  "is_mandatory": false
              },
              {
                  "name": "date check",
                  "id": 12777,
                  "slug": "2ndPoRQLuLpUuEDeyp6m",
                  "question_group": 2635,
                  "type": "date",
                  "validations": {},
                  "is_mandatory": false
              },
              {
                  "name": "date check",
                  "id": 12778,
                  "slug": "PK697nf8oR9I8Je99H7z",
                  "question_group": 2635,
                  "type": "date",
                  "validations": {},
                  "is_mandatory": false
              },
              {
                  "name": "pack shot",
                  "id": 13157,
                  "slug": "Xwa6ZZYBLuegfe4vJWxs",
                  "question_group": 2635,
                  "type": "multiple_image",
                  "validations": {
                      "min_images_count": "1",
                      "max_images_count": "4"
                  },
                  "is_mandatory": true
              }
          ],
          "index": 4
      },
      {
          "slug": "94ld8h9XRwYmi2J4l03o",
          "name": "Set of Images",
          "id": 2637,
          "type": "regular_group",
          "related_sku_products": [],
          "index": 6
      },
      {
          "slug": "5hAGlNlNlICx32VbNXVe",
          "name": "Single Multiple choice questions",
          "id": 3703,
          "type": "sku_group",
          "related_sku_products": [
              {
                  "name": "single choice",
                  "id": 12940,
                  "slug": "CiwWZFVbABazz5muFNng",
                  "question_group": 3703,
                  "type": "single_choice",
                  "validations": {
                      "choices": "abc;def;xyz"
                  },
                  "is_mandatory": false
              },
              {
                  "name": "single 1",
                  "id": 12941,
                  "slug": "iKuxv8iRvJ6GCUK3PkgA",
                  "question_group": 3703,
                  "type": "single_choice",
                  "validations": {
                      "choices": "1;2"
                  },
                  "is_mandatory": false
              },
              {
                  "name": "single 2",
                  "id": 12942,
                  "slug": "Hx1U3DofxQFT6qT3Zjr3",
                  "question_group": 3703,
                  "type": "single_choice",
                  "validations": {
                      "choices": "11;22;33"
                  },
                  "is_mandatory": false
              }
          ],
          "index": 7
      },
      {
          "slug": "nBt8OyiyCbAyIabOcpVz",
          "name": "Handle sub project",
          "id": 3719,
          "type": "regular_group",
          "related_sku_products": [],
          "index": 8
      },
      {
          "slug": "JIhllFURFEDdusmSA8Vu",
          "name": "Handle Sub project 2",
          "id": 3720,
          "type": "regular_group",
          "related_sku_products": [],
          "index": 9
      },
      {
          "slug": "cHBvsbXO2v17W0L3UDQG",
          "name": "Handle sub project dropd",
          "id": 3755,
          "type": "regular_group",
          "related_sku_products": [],
          "index": 10
      },
      {
        "slug": "5hAGlNlNlICx32VbNXVe",
        "name": "Collected Bills",
        "id": 3704,
        "type": "sku_group",
        "related_sku_products": [
            {
                "name": "single choice",
                "id": 12940,
                "slug": "CiwWZFVbABazz5muFNng",
                "question_group": 3703,
                "type": "single_choice",
                "validations": {
                    "choices": "abc;def;xyz"
                },
                "is_mandatory": false
            },
            {
                "name": "single 1",
                "id": 12941,
                "slug": "iKuxv8iRvJ6GCUK3PkgA",
                "question_group": 3703,
                "type": "single_choice",
                "validations": {
                    "choices": "1;2"
                },
                "is_mandatory": false
            },
            {
                "name": "single 2",
                "id": 12942,
                "slug": "Hx1U3DofxQFT6qT3Zjr3",
                "question_group": 3703,
                "type": "single_choice",
                "validations": {
                    "choices": "11;22;33"
                },
                "is_mandatory": false
            }
        ],
        "index": 7
    },
  ],
  "related_sibling_instances": [],
  "workflow_object": 6337815,
  "total_images_linked_to_answers": 8,
  "dob": null,
  "email": null,
  "address_proof": [],
  "bank_account_type": [
      {
          "type": "Savings",
          "show_ownership_proof_image": true
      },
      {
          "type": "Current",
          "show_ownership_proof_image": false
      }
  ],
  "ownership_proof": [],
  "bulk_kyc": false,
  "bulk_created_by": "",
  "bulk_approved_by": "",
  "updated_fields": {}
}

const getDetailsMenuName = (workflow_permission, type_of_calling) => {
  switch (workflow_permission) {
    case "calling-executive-workflow-permission": {
      if (type_of_calling === "validation") {
        return {
          menuName: "Calling Details",
          componentName: OutletCallingScreen,
        };
      } else if (type_of_calling === "intimation") {
        return {
          menuName: "Calling Details",
          componentName: IntimationCalling,
        };
      } else if (type_of_calling === "invoice") {
        return {
          menuName: "Calling Details",
          componentName: InvoiceCallingScreen,
        };
      } else if (type_of_calling === "kyc_update") {
        return {
          menuName: "Calling Details",
          componentName: KYCUpdate,
        };
      } else if (type_of_calling === "confirmation") {
        return {
          menuName: "Calling Details",
          componentName: ConfirmationCalling,
        };
      }
    }
    case "iftu-executive-workflow-permission":
    case "iftu-manager-workflow-permission":{
      return {
        menuName: "Payout Details",
        componentName: PayoutDetailsScreen,
        menuName2:"Survey Details",
        componentName2:OutletSurvey,
      };
    }

    default:
      return { menuName: "Survey Details", componentName: OutletSurvey };
      // return { menuName: "Survey Details", componentName: CollectedBills };
  }
};

class OutletDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outletDetails: {},
      outletGroupDetails: {},
      activeGroupIndex: 0,
      activeNavIndex: 1,
      totalImages: [],
      saveClickedData: {},
      workflowObjectActionConfig: {},
      callingTrackerList: [],
      selectedCallingTrackerReasonDetails: {},
      workflowStateChanged: false
    };
  }
  getOutletDetails = () => {
    const { match, workflow_permission } = this.props;
    const { outlet } = match.params;
    triggerSimpleAjax(
      `${GET_OUTLETS_DETAILS}${outlet}/`,
      "GET",
      {},
      (response) => {
        triggerSimpleAjax(
          `${UPLOAD_API}?related_multiple_images_answer_input__workflow_object__outlet=${response.id}&related_single_image_answer_input__workflow_object__outlet=${response.id}&or_based_filter_keys=related_single_image_answer_input__workflow_object__outlet,related_multiple_images_answer_input__workflow_object__outlet&no-pagination&all_survey_images=True`,
          "GET",
          {},
          (response) => {
            this.setState({ totalImages: response });
          }
        );

        if (workflow_permission === WORKFLOW_KEYS.CALLING_EXECUTIVE && response.related_workflow_object) {
          triggerSimpleAjax(
            `${GET_CALLING_TRACKER}?linked_outlets=${(response.related_workflow_object || {}).outlet}`,
            "GET",
            {},
            (response) => {

              this.setState({
                callingTrackerList: response.results
              })
            },
            (error) => {
              console.log(error);
            }
          );
        }
        const { sub_project } = response
        const isScoreDetail = this.showScoreDetail(sub_project.type)
        this.setState({ outletDetails: response, activeNavIndex: isScoreDetail ? 0 : (workflow_permission === "iftu-manager-workflow-permission" || workflow_permission === "iftu-executive-workflow-permission") ? 4 : 1 });
        // this.getGroupDetails(0);
      }
    );
  };
  // Sanjay: This was commented to prevent Dealer Board image getting displayed two times in Modal Popup
  // getTotalImages = () => {
  //   const { totalImages = [], outletDetails = {} } = this.state;
  //   let images = [];
  //   const {
  //     banner_image = null
  //   } = outletDetails;
  //   if (totalImages.length) {
  //     images = [
  //       ...totalImages,
  //     ]
  //   }
  //   if (banner_image) {
  //     images = [
  //       ...images,
  //       banner_image
  //     ]
  //   }
  //   return images
  // }

  componentDidMount() {
    const { history, match } = this.props
    const { outlet } = match.params;
    const stateData = JSON.parse(localStorage.getItem(outlet))
    if (stateData) {
      localStorage.removeItem(outlet)
      history.push(history.location.pathname, {
        ...stateData
      })
    }
    this.getOutletDetails();
  }

  componentDidUpdate(_, prevState) {
    if (prevState.activeGroupIndex !== this.state.activeGroupIndex) {
      // this.getGroupDetails(this.state.activeGroupIndex);
    }
  }

  showScoreDetail = (subProjectType) => {
    return WORKFLOW_KEYS.CLIENT_MANAGER === this.props.workflow_permission && subProjectType === SUB_PROJECT_TYPES.RETAIL_AUDIT_CYCLE
  }


  render() {
    const {
      outletDetails = {},
      outletGroupDetails,
      activeGroupIndex,
      activeNavIndex,
      totalImages,
      saveClickedData,
      workflowObjectActionConfig = {},
      selectedCallingTrackerReasonDetails = {},
      callingTrackerList = [],
      isUpdateActionListAvailable,
      workflowStateChanged
    } = this.state;
    const { workflow_permission, history, triggerNotification } = this.props;
    const { subProjectType, workflowOutletId } = history.location.state || {};
    const isCallingExecutive = WORKFLOW_KEYS.CALLING_EXECUTIVE === workflow_permission

    if (!Object.keys(outletDetails).length) {
      return <Loader />;
    }

    const {
      name,
      address,
      region,
      city,
      state,
      zip_code,
      created,
      question_groups,
      question_and_answers,
      logs = [],
      answer_action_logs=[],
      sub_project = {},
      status,
      related_workflow_object = {},
      id,
      outlet_id,
      rsid,
      related_sibling_instances = [],
      banner_image = {},
      inbound_source,
      field_survey_start_datetime,
      field_survey_end_datetime,
      field_survey_start_latitude,
      field_survey_end_latitude,
      field_survey_start_longitude,
      field_survey_end_longitude,
      notes_by_field_auditor = null,
      previous_address = null,
      distributor_gst_number = null
    } = outletDetails;


    console.log('outletDetails9999',question_groups);
    const { menuName, componentName,menuName2,componentName2 } = getDetailsMenuName(
      workflow_permission,
      (related_workflow_object || {}).type_of_calling,
    );

    

    const payoutTable =
      WORKFLOW_KEYS.IFTU_EXECUTIVE === workflow_permission &&
      (related_workflow_object || {}).type_of_calling === "confirmation";
    const isScoreDetail = this.showScoreDetail(sub_project.type)
    const isRetailEdit = related_workflow_object.interim_status
    const DetailWrapper = componentName;

    const DetailWrapper2 = componentName2;

    const openModal = window.location.search.split("=").includes("?modal")

    const {
      qc_inbound_date,
      workflow_state
    } = related_workflow_object || {};
    return (
      <Fragment>
        <OutletMeta
          {...outletDetails}
          outletName={name}
          address={address}
          region={region}
          city={city}
          state={state}
          zip_code={zip_code}
          created={created || undefined}
          qc_inbound_date={qc_inbound_date || undefined}
          workflow_permission={workflow_permission}
          subProjectType={subProjectType}
          workflowOutletId={workflowOutletId}
          status={status}
          saveClickedData={saveClickedData}
          question_groups={question_groups}
          outlet_id={outlet_id}
          rsid={rsid}
          inbound_source={inbound_source}
          related_workflow_object={related_workflow_object}
          field_survey_start_datetime={field_survey_start_datetime}
          field_survey_end_datetime={field_survey_end_datetime}
          field_survey_start_latitude={field_survey_start_latitude}
          field_survey_end_latitude={field_survey_end_latitude}
          field_survey_start_longitude={field_survey_start_longitude}
          field_survey_end_longitude={field_survey_end_longitude}
          notes_by_field_auditor={notes_by_field_auditor}
          previous_address={previous_address}
          distributor_gst_number={distributor_gst_number}
          setSaveClickedData={(data) =>
            this.setState({ saveClickedData: data })
          }
          selectedOutletDetails={[
            {
              subProject: sub_project.type,
              workflow_state: related_workflow_object.workflow_state,
              workflowOutletId: (related_workflow_object || {}).id,
            },
          ]}
          getOutletDetails={this.getOutletDetails}
          history={history}
          setWorkflowObjectActionConfig={(data) => {
            this.setState({
              workflowObjectActionConfig: data,
            });
          }}
          callingTrackerList={callingTrackerList}
          selectedCallingTrackerReasonDetails={
            selectedCallingTrackerReasonDetails
          }
          resetCallingtrackerResonDetails={() => {
            this.setState({
              selectedCallingTrackerReasonDetails: {},
            });
          }}
          triggerNotification={triggerNotification}
          updateActionListAvailable={(value) => {
            this.setState({ isUpdateActionListAvailable: value });
          }}
          banner_image={banner_image}
          setWorkflowStateChanged={(value) => this.setState({
            workflowStateChanged: value
          })}
          workflowStateChanged={workflowStateChanged}
          isCallingExecutive={isCallingExecutive}
        />
        <DetailsLayoutWrapper>
          <LeftSideNavs>

            {isScoreDetail ? (
              <li
                className={activeNavIndex === 0 ? "active" : ""}
                onClick={() => {
                  this.setState({ activeNavIndex: 0 });
                }}
              >
                <DonutLargeIcon  data-tip data-for={`score`} />
                <ReactTooltip place="right" id={`score`}>Score</ReactTooltip>
              </li>
            ) : null}
            {menuName2 && 
            <li
              className={activeNavIndex === 4 ? "active" : ""}
              onClick={() => {
                this.setState({ activeNavIndex: 4 });
              }}
            >
              <AssignmentIcon data-tip data-for={`survey-${menuName2}`} />
              <ReactTooltip place="right" id={`survey-${menuName2}`}>{menuName2}</ReactTooltip>
            </li>}
            <li
              className={activeNavIndex === 1 ? "active" : ""}
              onClick={() => {
                this.setState({ activeNavIndex: 1 });
              }}
            >
              <MobileFriendlyIcon data-tip data-for={`survey-${menuName}`} />
              <ReactTooltip place="right" id={`survey-${menuName}`}>{menuName}</ReactTooltip>
            </li>
            
            <li
              className={activeNavIndex === 2 ? "active" : ""}
              onClick={() => {
                this.setState({ activeNavIndex: 2 });
              }}
            >
              <VerticalSplitIcon data-tip data-for={`log-details`} style={{ transform: `rotate(180deg)` }} />
              <ReactTooltip place="right" id='log-details'>
                Log Details
              </ReactTooltip>
            </li>
            <li
              className={activeNavIndex === 5 ? "active" : ""}
              onClick={() => {
                this.setState({ activeNavIndex: 5 });
              }}
            >
              <HistoryRoundedIcon data-tip data-for={`qc-edit-history`} style={{ transform: `rotate(180deg)` }} />
              <ReactTooltip place="right" id='qc-edit-history'>
                QC Edit History
              </ReactTooltip>
            </li>
            {isRetailEdit ? (
              <li
                className={activeNavIndex === 3? "active" : ""}
                onClick={() => {
                  this.setState({activeNavIndex: 3})
                }}
              >
                <EditIcon data-tip data-for={`retailers-details`} />
                <ReactTooltip place="right" id='retailers-details'>
                  Retailers Edit
                </ReactTooltip>
              </li>
            ) : ""}
          </LeftSideNavs>
          <RightSideContent
            contentMargin={payoutTable ? "24px 24px 24px -10px" : ""}
            splitColumn={isCallingExecutive}
          >
            <section>
              {activeNavIndex === 0 && !openModal ? (
                <LogicScoreWrapper
                  slug={outletDetails.slug}
                  showCurrentSurveyImageTiles={false}
                  showPrevSurveyImageTiles={true}
                  totalImageCount={totalImages.length}
                  totalImages={totalImages}
                  newTab={true}
                  outletDetails={outletDetails}
                />
              ) : null}

              {activeNavIndex === 1 || openModal ? (
                <DetailWrapper
                  question_groups={question_groups}
                  question_and_answers={question_and_answers}
                  outletDetails={outletDetails}
                  sub_project={sub_project || {}}
                  // totalImages={this.getTotalImages()}
                  totalImages={totalImages}
                  saveClickedData={saveClickedData}
                  location={this.props.location}
                  setSaveClickedData={(data) =>
                    this.setState({ saveClickedData: data })
                  }
                  workflow_state={workflow_state}
                  workflow_permission={workflow_permission}
                  triggerNotification={triggerNotification}
                  history={history}
                  related_sibling_instances={related_sibling_instances || []}
                  isUpdateActionListAvailable={isUpdateActionListAvailable}
                  workflowStateChanged={workflowStateChanged}
                />
              ) : null}


              {activeNavIndex === 2 ? <OutletLog logs={logs || []} state={this.state} /> : null}
              {activeNavIndex === 5 ? <QCEditHistory logs={answer_action_logs || []} state={this.state} /> : null}

            </section>

            {isCallingExecutive && activeNavIndex === 1 ? (
              <div style={{
                "width": "25%"
              }}>
                <VerifyPhoneNumberBlock
                  callingTrackerReasonsConfig={
                    workflowObjectActionConfig.calling_tracker_reasons_config
                  }
                  callingTrackerList={callingTrackerList}
                  selectedCallingTrackerReasonDetails={
                    selectedCallingTrackerReasonDetails
                  }
                  onReasonSelect={(trackerReason) => {
                    this.setState({
                      selectedCallingTrackerReasonDetails: {
                        ...selectedCallingTrackerReasonDetails,
                        ...trackerReason,
                      },
                    });
                  }}
                />
                <ImageZoom
                  showCurrentSurveyImageTiles={false}
                  showPrevSurveyImageTiles={true}
                  totalImageCount={totalImages.length}
                  totalImages={totalImages}
                  newTab={true}
                  outlet_slug={outletDetails.slug}
                  outlet_id={outletDetails.id}
                />
              </div>

            ) : null}

            {activeNavIndex === 3 && isRetailEdit ? (
              <RetailerEdit
                outletDetails={outletDetails}
                getOutletDetails={this.getOutletDetails}
              />
            ): null }
          </RightSideContent>
        </DetailsLayoutWrapper>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const { app, user = {} } = state;

  const { considered_workflow_permission } = user.user_details || {};

  return {
    workflow_permission: considered_workflow_permission,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    triggerNotification: bindActionCreators(triggerNotification, dispatch)
  }
}
export default withLastLocation(connect(mapStateToProps, mapDispatchToProps)(OutletDetails));
