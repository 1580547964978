  const cardData = [
    {
      name: "INV/2024",
      invoiceType:"Inventory",
      headers: ["ID", "Item", "Quantity", "Price", "Category", "Supplier", "Date","Test","Status","Location","Location1", "Location2", "Location3", "Location4","Location5"],
      tableData: [
        {
          "id": 1,
          "item": "",
          "quantity": 2,
          "price": "$10",
          "category": "Category 1",
          "supplier": "Supplier A",
          "date": "2024-08-01",
          "test": "abc",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse13333sadsahsvdadfadfdadfdfdskrishnaksbjdfjhasvfajshfbasdjhfavsdhjASBfmhadcvahsjdbASDcbjzcvzivgzdbjzxcb vjhzcxjhvbzkchjxbv","Warehouse2","Warehouse3","Warehouse"],
  
        },
        {
          "id": 2,
          "item": "Item A2",
          "quantity": 4,
          "price": "$15",
          "category": "Category 2",
          "supplier": "Supplier B",
          "date": "2024-08-02",
          "test": "def",
          "status": "Out of Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse4",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1dlksnflsdfjknsbdkjfsbdfsmhdfvasdjfhafdkhsdbfvskdfvbsdjhsvbnzxvcaghdcfda","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 3,
          "item": "Item A3",
          "quantity": 3,
          "price": "$12",
          "category": "Category 3",
          "supplier": "Supplier C",
          "date": "2024-08-03",
          "test": "ghi",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse3",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 4,
          "item": "Item A4",
          "quantity": 5,
          "price": "$18",
          "category": "Category 1",
          "supplier": "Supplier D",
          "date": "2024-08-04",
          "test": "jkl",
          "status": "Low Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse2",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 5,
          "item": "Item A5",
          "quantity": 7,
          "price": "$20",
          "category": "Category 2",
          "supplier": "Supplier E",
          "date": "2024-08-05",
          "test": "mno",
          "status": "In Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse3",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 6,
          "item": "Item A6",
          "quantity": 6,
          "price": "$25",
          "category": "Category 3",
          "supplier": "Supplier F",
          "date": "2024-08-06",
          "test": "pqr",
          "status": "Out of Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse4",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 7,
          "item": "Item A7",
          "quantity": 3,
          "price": "$22",
          "category": "Category 1",
          "supplier": "Supplier G",
          "date": "2024-08-07",
          "test": "stu",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse2",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 8,
          "item": "Item A8",
          "quantity": 4,
          "price": "$14",
          "category": "Category 2",
          "supplier": "Supplier H",
          "date": "2024-08-08",
          "test": "vwx",
          "status": "Low Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse1",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 9,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse4",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 10,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse4",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 11,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse4",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 12,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse3",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 13,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse1",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 14,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse3",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        }
      ]
    },
    {
      name: "INV/2025",
      headers: ["ID", "Item", "Quantity", "Price", "Category", "Supplier", "Date","Test","Status","Location","Location1", "Location2", "Location3", "Location4","Location5"],
      tableData: [
        {
          "id": 1,
          "item": "Item A1",
          "quantity": 2,
          "price": "$10",
          "category": "Category 1",
          "supplier": "Supplier A",
          "date": "2024-08-01",
          "test": "abc",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse2",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
        },
        {
          "id": 2,
          "item": "Item A2",
          "quantity": 4,
          "price": "$15",
          "category": "Category 2",
          "supplier": "Supplier B",
          "date": "2024-08-02",
          "test": "def",
          "status": "Out of Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse1",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 3,
          "item": "Item A3",
          "quantity": 3,
          "price": "$12",
          "category": "Category 3",
          "supplier": "Supplier C",
          "date": "2024-08-03",
          "test": "ghi",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse3",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 4,
          "item": "Item A4",
          "quantity": 5,
          "price": "$18",
          "category": "Category 1",
          "supplier": "Supplier D",
          "date": "2024-08-04",
          "test": "jkl",
          "status": "Low Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse4",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 5,
          "item": "Item A5",
          "quantity": 7,
          "price": "$20",
          "category": "Category 2",
          "supplier": "Supplier E",
          "date": "2024-08-05",
          "test": "mno",
          "status": "In Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse2",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 6,
          "item": "Item A6",
          "quantity": 6,
          "price": "$25",
          "category": "Category 3",
          "supplier": "Supplier F",
          "date": "2024-08-06",
          "test": "pqr",
          "status": "Out of Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse1",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 7,
          "item": "Item A7",
          "quantity": 3,
          "price": "$22",
          "category": "Category 1",
          "supplier": "Supplier G",
          "date": "2024-08-07",
          "test": "stu",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse2",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 8,
          "item": "Item A8",
          "quantity": 4,
          "price": "$14",
          "category": "Category 2",
          "supplier": "Supplier H",
          "date": "2024-08-08",
          "test": "vwx",
          "status": "Low Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 9,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse2",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 10,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse4",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 11,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse2",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 12,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse2",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 13,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse2",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 14,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse1",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        }
      ]
    },
    {
      name: "INV/2024",
      headers: ["ID", "Item", "Quantity", "Price", "Category", "Supplier", "Date","Test","Status","Location","Location1", "Location2", "Location3", "Location4","Location5"],
      tableData: [
        {
          "id": 1,
          "item": "Item A1",
          "quantity": 2,
          "price": "$10",
          "category": "Category 1",
          "supplier": "Supplier A",
          "date": "2024-08-01",
          "test": "abc",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse3",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
        },
        {
          "id": 2,
          "item": "Item A2",
          "quantity": 4,
          "price": "$15",
          "category": "Category 2",
          "supplier": "Supplier B",
          "date": "2024-08-02",
          "test": "def",
          "status": "Out of Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse4",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 3,
          "item": "Item A3",
          "quantity": 3,
          "price": "$12",
          "category": "Category 3",
          "supplier": "Supplier C",
          "date": "2024-08-03",
          "test": "ghi",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 4,
          "item": "Item A4",
          "quantity": 5,
          "price": "$18",
          "category": "Category 1",
          "supplier": "Supplier D",
          "date": "2024-08-04",
          "test": "jkl",
          "status": "Low Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 5,
          "item": "Item A5",
          "quantity": 7,
          "price": "$20",
          "category": "Category 2",
          "supplier": "Supplier E",
          "date": "2024-08-05",
          "test": "mno",
          "status": "In Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 6,
          "item": "Item A6",
          "quantity": 6,
          "price": "$25",
          "category": "Category 3",
          "supplier": "Supplier F",
          "date": "2024-08-06",
          "test": "pqr",
          "status": "Out of Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 7,
          "item": "Item A7",
          "quantity": 3,
          "price": "$22",
          "category": "Category 1",
          "supplier": "Supplier G",
          "date": "2024-08-07",
          "test": "stu",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 8,
          "item": "Item A8",
          "quantity": 4,
          "price": "$14",
          "category": "Category 2",
          "supplier": "Supplier H",
          "date": "2024-08-08",
          "test": "vwx",
          "status": "Low Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 9,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 10,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 11,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 12,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 13,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 14,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        }
      ]
    },
    {
      name: "INV/2024",
      headers: ["ID", "Item", "Quantity", "Price", "Category", "Supplier", "Date","Test","Status","Location","Location1", "Location2", "Location3", "Location4","Location5"],
      tableData: [
        {
          "id": 1,
          "item": "Item A1",
          "quantity": 2,
          "price": "$10",
          "category": "Category 1",
          "supplier": "Supplier A",
          "date": "2024-08-01",
          "test": "abc",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
        },
        {
          "id": 2,
          "item": "Item A2",
          "quantity": 4,
          "price": "$15",
          "category": "Category 2",
          "supplier": "Supplier B",
          "date": "2024-08-02",
          "test": "def",
          "status": "Out of Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 3,
          "item": "Item A3",
          "quantity": 3,
          "price": "$12",
          "category": "Category 3",
          "supplier": "Supplier C",
          "date": "2024-08-03",
          "test": "ghi",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 4,
          "item": "Item A4",
          "quantity": 5,
          "price": "$18",
          "category": "Category 1",
          "supplier": "Supplier D",
          "date": "2024-08-04",
          "test": "jkl",
          "status": "Low Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 5,
          "item": "Item A5",
          "quantity": 7,
          "price": "$20",
          "category": "Category 2",
          "supplier": "Supplier E",
          "date": "2024-08-05",
          "test": "mno",
          "status": "In Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 6,
          "item": "Item A6",
          "quantity": 6,
          "price": "$25",
          "category": "Category 3",
          "supplier": "Supplier F",
          "date": "2024-08-06",
          "test": "pqr",
          "status": "Out of Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 7,
          "item": "Item A7",
          "quantity": 3,
          "price": "$22",
          "category": "Category 1",
          "supplier": "Supplier G",
          "date": "2024-08-07",
          "test": "stu",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 8,
          "item": "Item A8",
          "quantity": 4,
          "price": "$14",
          "category": "Category 2",
          "supplier": "Supplier H",
          "date": "2024-08-08",
          "test": "vwx",
          "status": "Low Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 9,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 10,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 11,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 12,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 13,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 14,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        }
      ]
    },
    {
      name: "INV/2024",
      headers: ["ID", "Item", "Quantity", "Price", "Category", "Supplier", "Date","Test","Status","Location","Location1", "Location2", "Location3", "Location4","Location5"],
      tableData: [
        {
          "id": 1,
          "item": "Item A1",
          "quantity": 2,
          "price": "$10",
          "category": "Category 1",
          "supplier": "Supplier A",
          "date": "2024-08-01",
          "test": "abc",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
        },
        {
          "id": 2,
          "item": "Item A2",
          "quantity": 4,
          "price": "$15",
          "category": "Category 2",
          "supplier": "Supplier B",
          "date": "2024-08-02",
          "test": "def",
          "status": "Out of Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 3,
          "item": "Item A3",
          "quantity": 3,
          "price": "$12",
          "category": "Category 3",
          "supplier": "Supplier C",
          "date": "2024-08-03",
          "test": "ghi",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 4,
          "item": "Item A4",
          "quantity": 5,
          "price": "$18",
          "category": "Category 1",
          "supplier": "Supplier D",
          "date": "2024-08-04",
          "test": "jkl",
          "status": "Low Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 5,
          "item": "Item A5",
          "quantity": 7,
          "price": "$20",
          "category": "Category 2",
          "supplier": "Supplier E",
          "date": "2024-08-05",
          "test": "mno",
          "status": "In Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 6,
          "item": "Item A6",
          "quantity": 6,
          "price": "$25",
          "category": "Category 3",
          "supplier": "Supplier F",
          "date": "2024-08-06",
          "test": "pqr",
          "status": "Out of Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 7,
          "item": "Item A7",
          "quantity": 3,
          "price": "$22",
          "category": "Category 1",
          "supplier": "Supplier G",
          "date": "2024-08-07",
          "test": "stu",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 8,
          "item": "Item A8",
          "quantity": 4,
          "price": "$14",
          "category": "Category 2",
          "supplier": "Supplier H",
          "date": "2024-08-08",
          "test": "vwx",
          "status": "Low Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 9,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 10,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 11,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 12,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 13,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 14,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        }
      ]
    },
    {
      name: "INV/2024",
      headers: ["ID", "Item", "Quantity", "Price", "Category", "Supplier", "Date","Test","Status","Location","Location1", "Location2", "Location3", "Location4","Location5"],
      tableData: [
        {
          "id": 1,
          "item": "Item A1",
          "quantity": 2,
          "price": "$10",
          "category": "Category 1",
          "supplier": "Supplier A",
          "date": "2024-08-01",
          "test": "abc",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
        },
        {
          "id": 2,
          "item": "Item A2",
          "quantity": 4,
          "price": "$15",
          "category": "Category 2",
          "supplier": "Supplier B",
          "date": "2024-08-02",
          "test": "def",
          "status": "Out of Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 3,
          "item": "Item A3",
          "quantity": 3,
          "price": "$12",
          "category": "Category 3",
          "supplier": "Supplier C",
          "date": "2024-08-03",
          "test": "ghi",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 4,
          "item": "Item A4",
          "quantity": 5,
          "price": "$18",
          "category": "Category 1",
          "supplier": "Supplier D",
          "date": "2024-08-04",
          "test": "jkl",
          "status": "Low Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 5,
          "item": "Item A5",
          "quantity": 7,
          "price": "$20",
          "category": "Category 2",
          "supplier": "Supplier E",
          "date": "2024-08-05",
          "test": "mno",
          "status": "In Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 6,
          "item": "Item A6",
          "quantity": 6,
          "price": "$25",
          "category": "Category 3",
          "supplier": "Supplier F",
          "date": "2024-08-06",
          "test": "pqr",
          "status": "Out of Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 7,
          "item": "Item A7",
          "quantity": 3,
          "price": "$22",
          "category": "Category 1",
          "supplier": "Supplier G",
          "date": "2024-08-07",
          "test": "stu",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 8,
          "item": "Item A8",
          "quantity": 4,
          "price": "$14",
          "category": "Category 2",
          "supplier": "Supplier H",
          "date": "2024-08-08",
          "test": "vwx",
          "status": "Low Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 9,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 10,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 11,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 12,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 13,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 14,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        }
      ]
    },
    {
      name: "INV/2024",
      headers: ["ID", "Item", "Quantity", "Price", "Category", "Supplier", "Date","Test","Status","Location","Location1", "Location2", "Location3", "Location4","Location5"],
      tableData: [
        {
          "id": 1,
          "item": "Item A1",
          "quantity": 2,
          "price": "$10",
          "category": "Category 1",
          "supplier": "Supplier A",
          "date": "2024-08-01",
          "test": "abc",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
        },
        {
          "id": 2,
          "item": "Item A2",
          "quantity": 4,
          "price": "$15",
          "category": "Category 2",
          "supplier": "Supplier B",
          "date": "2024-08-02",
          "test": "def",
          "status": "Out of Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 3,
          "item": "Item A3",
          "quantity": 3,
          "price": "$12",
          "category": "Category 3",
          "supplier": "Supplier C",
          "date": "2024-08-03",
          "test": "ghi",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 4,
          "item": "Item A4",
          "quantity": 5,
          "price": "$18",
          "category": "Category 1",
          "supplier": "Supplier D",
          "date": "2024-08-04",
          "test": "jkl",
          "status": "Low Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 5,
          "item": "Item A5",
          "quantity": 7,
          "price": "$20",
          "category": "Category 2",
          "supplier": "Supplier E",
          "date": "2024-08-05",
          "test": "mno",
          "status": "In Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 6,
          "item": "Item A6",
          "quantity": 6,
          "price": "$25",
          "category": "Category 3",
          "supplier": "Supplier F",
          "date": "2024-08-06",
          "test": "pqr",
          "status": "Out of Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 7,
          "item": "Item A7",
          "quantity": 3,
          "price": "$22",
          "category": "Category 1",
          "supplier": "Supplier G",
          "date": "2024-08-07",
          "test": "stu",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 8,
          "item": "Item A8",
          "quantity": 4,
          "price": "$14",
          "category": "Category 2",
          "supplier": "Supplier H",
          "date": "2024-08-08",
          "test": "vwx",
          "status": "Low Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 9,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 10,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 11,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 12,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 13,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 14,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        }
      ]
    },
    {
      name: "INV/2024",
      headers: ["ID", "Item", "Quantity", "Price", "Category", "Supplier", "Date","Test","Status","Location","Location1", "Location2", "Location3", "Location4","Location5"],
      tableData: [
        {
          "id": 1,
          "item": "Item A1",
          "quantity": 2,
          "price": "$10",
          "category": "Category 1",
          "supplier": "Supplier A",
          "date": "2024-08-01",
          "test": "abc",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
        },
        {
          "id": 2,
          "item": "Item A2",
          "quantity": 4,
          "price": "$15",
          "category": "Category 2",
          "supplier": "Supplier B",
          "date": "2024-08-02",
          "test": "def",
          "status": "Out of Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 3,
          "item": "Item A3",
          "quantity": 3,
          "price": "$12",
          "category": "Category 3",
          "supplier": "Supplier C",
          "date": "2024-08-03",
          "test": "ghi",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 4,
          "item": "Item A4",
          "quantity": 5,
          "price": "$18",
          "category": "Category 1",
          "supplier": "Supplier D",
          "date": "2024-08-04",
          "test": "jkl",
          "status": "Low Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 5,
          "item": "Item A5",
          "quantity": 7,
          "price": "$20",
          "category": "Category 2",
          "supplier": "Supplier E",
          "date": "2024-08-05",
          "test": "mno",
          "status": "In Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 6,
          "item": "Item A6",
          "quantity": 6,
          "price": "$25",
          "category": "Category 3",
          "supplier": "Supplier F",
          "date": "2024-08-06",
          "test": "pqr",
          "status": "Out of Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 7,
          "item": "Item A7",
          "quantity": 3,
          "price": "$22",
          "category": "Category 1",
          "supplier": "Supplier G",
          "date": "2024-08-07",
          "test": "stu",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 8,
          "item": "Item A8",
          "quantity": 4,
          "price": "$14",
          "category": "Category 2",
          "supplier": "Supplier H",
          "date": "2024-08-08",
          "test": "vwx",
          "status": "Low Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 9,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 10,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 11,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 12,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 13,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 14,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        }
      ]
    },
    {
      name: "INV/2024",
      headers: ["ID", "Item", "Quantity", "Price", "Category", "Supplier", "Date","Test","Status","Location","Location1", "Location2", "Location3", "Location4","Location5"],
      tableData: [
        {
          "id": 1,
          "item": "Item A1",
          "quantity": 2,
          "price": "$10",
          "category": "Category 1",
          "supplier": "Supplier A",
          "date": "2024-08-01",
          "test": "abc",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
        },
        {
          "id": 2,
          "item": "Item A2",
          "quantity": 4,
          "price": "$15",
          "category": "Category 2",
          "supplier": "Supplier B",
          "date": "2024-08-02",
          "test": "def",
          "status": "Out of Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 3,
          "item": "Item A3",
          "quantity": 3,
          "price": "$12",
          "category": "Category 3",
          "supplier": "Supplier C",
          "date": "2024-08-03",
          "test": "ghi",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 4,
          "item": "Item A4",
          "quantity": 5,
          "price": "$18",
          "category": "Category 1",
          "supplier": "Supplier D",
          "date": "2024-08-04",
          "test": "jkl",
          "status": "Low Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 5,
          "item": "Item A5",
          "quantity": 7,
          "price": "$20",
          "category": "Category 2",
          "supplier": "Supplier E",
          "date": "2024-08-05",
          "test": "mno",
          "status": "In Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 6,
          "item": "Item A6",
          "quantity": 6,
          "price": "$25",
          "category": "Category 3",
          "supplier": "Supplier F",
          "date": "2024-08-06",
          "test": "pqr",
          "status": "Out of Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 7,
          "item": "Item A7",
          "quantity": 3,
          "price": "$22",
          "category": "Category 1",
          "supplier": "Supplier G",
          "date": "2024-08-07",
          "test": "stu",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 8,
          "item": "Item A8",
          "quantity": 4,
          "price": "$14",
          "category": "Category 2",
          "supplier": "Supplier H",
          "date": "2024-08-08",
          "test": "vwx",
          "status": "Low Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 9,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 10,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 11,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 12,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 13,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 14,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        }
      ]
    },

    {
      name: "INV/2024",
      headers: ["ID", "Item", "Quantity", "Price", "Category", "Supplier", "Date","Test","Status","Location","Location1", "Location2", "Location3", "Location4","Location5"],
      tableData: [
        {
          "id": 1,
          "item": "Item A1",
          "quantity": 2,
          "price": "$10",
          "category": "Category 1",
          "supplier": "Supplier A",
          "date": "2024-08-01",
          "test": "abc",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
        },
        {
          "id": 2,
          "item": "Item A2",
          "quantity": 4,
          "price": "$15",
          "category": "Category 2",
          "supplier": "Supplier B",
          "date": "2024-08-02",
          "test": "def",
          "status": "Out of Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 3,
          "item": "Item A3",
          "quantity": 3,
          "price": "$12",
          "category": "Category 3",
          "supplier": "Supplier C",
          "date": "2024-08-03",
          "test": "ghi",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 4,
          "item": "Item A4",
          "quantity": 5,
          "price": "$18",
          "category": "Category 1",
          "supplier": "Supplier D",
          "date": "2024-08-04",
          "test": "jkl",
          "status": "Low Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 5,
          "item": "Item A5",
          "quantity": 7,
          "price": "$20",
          "category": "Category 2",
          "supplier": "Supplier E",
          "date": "2024-08-05",
          "test": "mno",
          "status": "In Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 6,
          "item": "Item A6",
          "quantity": 6,
          "price": "$25",
          "category": "Category 3",
          "supplier": "Supplier F",
          "date": "2024-08-06",
          "test": "pqr",
          "status": "Out of Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 7,
          "item": "Item A7",
          "quantity": 3,
          "price": "$22",
          "category": "Category 1",
          "supplier": "Supplier G",
          "date": "2024-08-07",
          "test": "stu",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 8,
          "item": "Item A8",
          "quantity": 4,
          "price": "$14",
          "category": "Category 2",
          "supplier": "Supplier H",
          "date": "2024-08-08",
          "test": "vwx",
          "status": "Low Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 9,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 10,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 11,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 12,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 13,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 14,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        }
      ]
    },
    {
      name: "INV/2024",
      headers: ["ID", "Item", "Quantity", "Price", "Category", "Supplier", "Date","Test","Status","Location","Location1", "Location2", "Location3", "Location4","Location5"],
      tableData: [
        {
          "id": 1,
          "item": "Item A1",
          "quantity": 2,
          "price": "$10",
          "category": "Category 1",
          "supplier": "Supplier A",
          "date": "2024-08-01",
          "test": "abc",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
        },
        {
          "id": 2,
          "item": "Item A2",
          "quantity": 4,
          "price": "$15",
          "category": "Category 2",
          "supplier": "Supplier B",
          "date": "2024-08-02",
          "test": "def",
          "status": "Out of Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 3,
          "item": "Item A3",
          "quantity": 3,
          "price": "$12",
          "category": "Category 3",
          "supplier": "Supplier C",
          "date": "2024-08-03",
          "test": "ghi",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 4,
          "item": "Item A4",
          "quantity": 5,
          "price": "$18",
          "category": "Category 1",
          "supplier": "Supplier D",
          "date": "2024-08-04",
          "test": "jkl",
          "status": "Low Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 5,
          "item": "Item A5",
          "quantity": 7,
          "price": "$20",
          "category": "Category 2",
          "supplier": "Supplier E",
          "date": "2024-08-05",
          "test": "mno",
          "status": "In Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 6,
          "item": "Item A6",
          "quantity": 6,
          "price": "$25",
          "category": "Category 3",
          "supplier": "Supplier F",
          "date": "2024-08-06",
          "test": "pqr",
          "status": "Out of Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 7,
          "item": "Item A7",
          "quantity": 3,
          "price": "$22",
          "category": "Category 1",
          "supplier": "Supplier G",
          "date": "2024-08-07",
          "test": "stu",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 8,
          "item": "Item A8",
          "quantity": 4,
          "price": "$14",
          "category": "Category 2",
          "supplier": "Supplier H",
          "date": "2024-08-08",
          "test": "vwx",
          "status": "Low Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 9,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 10,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 11,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 12,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 13,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 14,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        }
      ]
    },
    {
      name: "INV/2024",
      headers: ["ID", "Item", "Quantity", "Price", "Category", "Supplier", "Date","Test","Status","Location","Location1", "Location2", "Location3", "Location4","Location5"],
      tableData: [
        {
          "id": 1,
          "item": "Item A1",
          "quantity": 2,
          "price": "$10",
          "category": "Category 1",
          "supplier": "Supplier A",
          "date": "2024-08-01",
          "test": "abc",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
        },
        {
          "id": 2,
          "item": "Item A2",
          "quantity": 4,
          "price": "$15",
          "category": "Category 2",
          "supplier": "Supplier B",
          "date": "2024-08-02",
          "test": "def",
          "status": "Out of Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 3,
          "item": "Item A3",
          "quantity": 3,
          "price": "$12",
          "category": "Category 3",
          "supplier": "Supplier C",
          "date": "2024-08-03",
          "test": "ghi",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 4,
          "item": "Item A4",
          "quantity": 5,
          "price": "$18",
          "category": "Category 1",
          "supplier": "Supplier D",
          "date": "2024-08-04",
          "test": "jkl",
          "status": "Low Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 5,
          "item": "Item A5",
          "quantity": 7,
          "price": "$20",
          "category": "Category 2",
          "supplier": "Supplier E",
          "date": "2024-08-05",
          "test": "mno",
          "status": "In Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 6,
          "item": "Item A6",
          "quantity": 6,
          "price": "$25",
          "category": "Category 3",
          "supplier": "Supplier F",
          "date": "2024-08-06",
          "test": "pqr",
          "status": "Out of Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 7,
          "item": "Item A7",
          "quantity": 3,
          "price": "$22",
          "category": "Category 1",
          "supplier": "Supplier G",
          "date": "2024-08-07",
          "test": "stu",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 8,
          "item": "Item A8",
          "quantity": 4,
          "price": "$14",
          "category": "Category 2",
          "supplier": "Supplier H",
          "date": "2024-08-08",
          "test": "vwx",
          "status": "Low Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 9,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 10,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 11,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 12,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 13,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 14,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        }
      ]
    },
    {
      name: "INV/2024",
      headers: ["ID", "Item", "Quantity", "Price", "Category", "Supplier", "Date","Test","Status","Location","Location1", "Location2", "Location3", "Location4","Location5"],
      tableData: [
        {
          "id": 1,
          "item": "Item A1",
          "quantity": 2,
          "price": "$10",
          "category": "Category 1",
          "supplier": "Supplier A",
          "date": "2024-08-01",
          "test": "abc",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
        },
        {
          "id": 2,
          "item": "Item A2",
          "quantity": 4,
          "price": "$15",
          "category": "Category 2",
          "supplier": "Supplier B",
          "date": "2024-08-02",
          "test": "def",
          "status": "Out of Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 3,
          "item": "Item A3",
          "quantity": 3,
          "price": "$12",
          "category": "Category 3",
          "supplier": "Supplier C",
          "date": "2024-08-03",
          "test": "ghi",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 4,
          "item": "Item A4",
          "quantity": 5,
          "price": "$18",
          "category": "Category 1",
          "supplier": "Supplier D",
          "date": "2024-08-04",
          "test": "jkl",
          "status": "Low Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 5,
          "item": "Item A5",
          "quantity": 7,
          "price": "$20",
          "category": "Category 2",
          "supplier": "Supplier E",
          "date": "2024-08-05",
          "test": "mno",
          "status": "In Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 6,
          "item": "Item A6",
          "quantity": 6,
          "price": "$25",
          "category": "Category 3",
          "supplier": "Supplier F",
          "date": "2024-08-06",
          "test": "pqr",
          "status": "Out of Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 7,
          "item": "Item A7",
          "quantity": 3,
          "price": "$22",
          "category": "Category 1",
          "supplier": "Supplier G",
          "date": "2024-08-07",
          "test": "stu",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 8,
          "item": "Item A8",
          "quantity": 4,
          "price": "$14",
          "category": "Category 2",
          "supplier": "Supplier H",
          "date": "2024-08-08",
          "test": "vwx",
          "status": "Low Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 9,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 10,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 11,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 12,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 13,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 14,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        }
      ]
    },
    {
      name: "INV/2024",
      headers: ["ID", "Item", "Quantity", "Price", "Category", "Supplier", "Date","Test","Status","Location","Location1", "Location2", "Location3", "Location4","Location5"],
      tableData: [
        {
          "id": 1,
          "item": "Item A1",
          "quantity": 2,
          "price": "$10",
          "category": "Category 1",
          "supplier": "Supplier A",
          "date": "2024-08-01",
          "test": "abc",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
        },
        {
          "id": 2,
          "item": "Item A2",
          "quantity": 4,
          "price": "$15",
          "category": "Category 2",
          "supplier": "Supplier B",
          "date": "2024-08-02",
          "test": "def",
          "status": "Out of Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 3,
          "item": "Item A3",
          "quantity": 3,
          "price": "$12",
          "category": "Category 3",
          "supplier": "Supplier C",
          "date": "2024-08-03",
          "test": "ghi",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 4,
          "item": "Item A4",
          "quantity": 5,
          "price": "$18",
          "category": "Category 1",
          "supplier": "Supplier D",
          "date": "2024-08-04",
          "test": "jkl",
          "status": "Low Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 5,
          "item": "Item A5",
          "quantity": 7,
          "price": "$20",
          "category": "Category 2",
          "supplier": "Supplier E",
          "date": "2024-08-05",
          "test": "mno",
          "status": "In Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 6,
          "item": "Item A6",
          "quantity": 6,
          "price": "$25",
          "category": "Category 3",
          "supplier": "Supplier F",
          "date": "2024-08-06",
          "test": "pqr",
          "status": "Out of Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 7,
          "item": "Item A7",
          "quantity": 3,
          "price": "$22",
          "category": "Category 1",
          "supplier": "Supplier G",
          "date": "2024-08-07",
          "test": "stu",
          "status": "In Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 8,
          "item": "Item A8",
          "quantity": 4,
          "price": "$14",
          "category": "Category 2",
          "supplier": "Supplier H",
          "date": "2024-08-08",
          "test": "vwx",
          "status": "Low Stock",
          "location": "Warehouse 2",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 9,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 10,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 11,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 12,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 13,
          "item": "Item A9",
          "quantity": 2,
          "price": "$19",
          "category": "Category 3",
          "supplier": "Supplier I",
          "date": "2024-08-09",
          "test": "yz",
          "status": "In Stock",
          "location": "Warehouse 3",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        },
        {
          "id": 14,
          "item": "Item A10",
          "quantity": 5,
          "price": "$16",
          "category": "Category 1",
          "supplier": "Supplier J",
          "date": "2024-08-10",
          "test": "123",
          "status": "Out of Stock",
          "location": "Warehouse 1",
          "location1": "Warehouse 1",
          "location2": "Warehouse 1",
          "location3": "Warehouse 1",
          "location4":"Warehouse",
          "location5": "Warehouse 1",
          "locationArray":["Warehouse1","Warehouse2","Warehouse3","Warehouse4"],
  
  
        }
      ]
    },
    
  
  ];
  export default cardData;
